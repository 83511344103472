<template>
  <div class="bg-blue-800 mt-9 lg:mt-0">
    <div class="relative text-center lg:mx-64 mx-2 text-white">
      <div class="h-4"></div>
      <h1 class="lg:text-6xl text-2xl font-mono">Contactez<br />nous !</h1>
      <div class="flex mt-6 lg:mt-16 justify-center lg:items-center">
        <div class="bg-purple-900 rounded-full lg:h-16 lg:w-16 h-9 w-9">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="lg:ml-3 lg:mt-3 mt-1 ml-1.5 lg:w-9 lg:h-9 w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
            />
          </svg>
        </div>

        <a
          href="#"
          class="text-xl font-medium text-white text-left ml-2 lg:ml-6"
          >support@csetrainings.com
        </a>
      </div>
      <div class="lg:mt-24 mt-6 text-center mx-auto">
        <p class="lg:text-4xl text-xl text-white font-mono">
          Plus de questions ?
        </p>
        <p class="text-lg text-white font-serif mt-4 lg:mx-32 mx-6">
          Si vous avez d'autres problèmes ou des questions non liées aux
          tutoriaux, envoyez-nous un courriel à l'adresse
          support@csetrainings.com pour toute autre question. Nous répondons
          généralement dans un délai de 1 à 3 jours ouvrables.
        </p>
        <form @submit.prevent="submitForm" class="mt-6">
          <div class="mb-4">
            <div class="mb-6">
              <input
                v-model="formData.name"
                class="shadow appearance-none border mt-4 rounded lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="name"
                placeholder="Nom"
              />
            </div>
            <div class="mb-6">
              <input
                v-model="formData.email"
                class="shadow appearance-none border mt-4 rounded lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="email"
                placeholder="E-mail"
              />
            </div>
            <textarea
              v-model="formData.message"
              class="shadow appearance-none border rounded lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="question"
              name="question"
              placeholder="Message"
              rows="4"
            ></textarea>
          </div>

          <div class="lg:flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 w-lg:w-full w-80 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              <span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> Envoyer</span>
            </button>
          </div>
        </form>
      </div>
      <div class="h-9"></div>
    </div>
  </div>

  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";
import spiner from "../components/spiner.vue";
import axios from 'axios';
export default {
  name: "App",
  components: {
    ModalComponent,
    spiner
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      isModalOpen: false,
      isLoading: false,
    };
  },
  mounted() {
    this.startSlider();
  },
  methods: {
    
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    openModal() {
      this.isModalOpen = true; // Ouvrir le modal
    },
    closeModal() {
      this.isModalOpen = false; // Fermer le modal
    },
    submitForm() {
      this.isLoading = true;
      axios.post('/api/sendemail', this.formData)
        .then(response => {
          this.formData={},
          this.isLoading = false;
          console.log(response.data);
        })
        .catch(error => {
         this.isLoading = false;
          console.error(error);
        });
    }
  },
};
</script>

<style scoped>
.custom-background2 {
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
